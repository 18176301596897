window.addEventListener( 'click', ( event ) => {
	if ( !( 'pirsch' in window ) ) return;

	const target = event.target;
	if ( !target || !( target instanceof HTMLElement ) ) return;
	if ( !target.matches( 'a' ) ) return;

	const anchor = target as HTMLAnchorElement;

	let kind = '';
	if ( isDropboxDownloadLink( anchor ) ) {
		kind = 'Dropbox';
	} else if ( isAssetDownloadLink( anchor ) ) {
		kind = 'Asset';
	} else {
		return;
	}

	// @ts-expect-error pirsch is a global
	window.pirsch( 'External Links', {
		meta: {
			Source: kind,
			Label: anchor.innerText,
			Target: anchor.href,
		},
	} ).catch( ( e: unknown ) => {
		console.error( e );
	} );
} );

function isDropboxDownloadLink( anchor: HTMLAnchorElement ) {
	return anchor.hostname === 'www.dropbox.com' && ( anchor.href.includes( 'dl=0' ) || anchor.href.includes( 'dl=1' ) );
}

function isAssetDownloadLink( anchor: HTMLAnchorElement ) {
	return anchor.hasAttribute( 'data-trackable-asset-link' );
}
