import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrNavigationOverlay extends MrModalDialog {
	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-modal-dialog' );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-modal-dialog' );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.modal-dialog__content' ),
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					duration: 192,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.modal-dialog__content' ),
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					duration: 96,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-navigation-overlay', MrNavigationOverlay );
