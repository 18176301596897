const isHoverDevice = window.matchMedia( '(hover: hover)' );

class MrHotspot extends HTMLElement {
	#resizeObserver = new ResizeObserver( () => {
		this.close();
		this.removeAttribute( 'data-anchor-position' );

		const details = this.querySelector<HTMLElement>( '[data-hotspot-details]' );
		if ( details ) {
			details.style.left = '';
		}
	} );

	#toggleHandler = ( event: Event ) => {
		if ( event.target !== this ) {
			return;
		}

		event.preventDefault();
		event.stopPropagation();

		if ( this.isOpen() ) {
			this.close();

			return;
		}

		this.open();
	};

	#focusinHandler = () => {
		this.ensureDetailsFitsWithinBounds();
		this.closeAllOtherHotspots();
	};

	#openHandler = () => {
		if ( !this.isOpen() ) {
			this.open();
		}
	};

	#closeHandler = () => {
		if ( this.isOpen() ) {
			this.close();
		}
	};

	connectedCallback() {
		this.parentElement?.addEventListener( 'click', this.#toggleHandler );

		if ( isHoverDevice.matches ) {
			this.parentElement?.addEventListener( 'mouseenter', this.#openHandler );
			this.parentElement?.addEventListener( 'mouseleave', this.#closeHandler );
		}

		this.addEventListener( 'focusin', this.#focusinHandler );

		const area = this.closest( '[data-hotspots-render-area]' );
		if ( area ) {
			this.#resizeObserver.observe( area );
		}
	}

	disconnectedCallback() {
		this.parentElement?.removeEventListener( 'click', this.#toggleHandler );
		this.parentElement?.removeEventListener( 'mouseenter', this.#openHandler );
		this.parentElement?.removeEventListener( 'mouseleave', this.#closeHandler );

		this.removeEventListener( 'focusin', this.#focusinHandler );

		this.#resizeObserver.unobserve( this );
	}


	isOpen() {
		return this.getAttribute( 'data-state' ) === 'open';
	}

	open() {
		this.closeAllOtherHotspots();
		this.ensureDetailsFitsWithinBounds();
		this.setAttribute( 'data-state', 'open' );
	}

	close() {
		this.setAttribute( 'data-state', 'closed' );
	}

	ensureDetailsFitsWithinBounds() {
		if ( this.hasAttribute( 'data-anchor-position' ) ) {
			return;
		}

		const details = this.querySelector<HTMLElement>( '[data-hotspot-details]' );
		if ( !details ) {
			return;
		}

		const mainBoundaries = this.closest( '[data-hotspots-render-area]' )?.getBoundingClientRect();
		const thisBoundaries = this.getBoundingClientRect();

		if ( !mainBoundaries ) {
			return;
		}

		const thisBoundariesTop = thisBoundaries.top - mainBoundaries.top;
		const thisBoundariesLeft = thisBoundaries.left - mainBoundaries.left;
		const thisBoundariesBottom = mainBoundaries.bottom - thisBoundaries.bottom;
		const thisBoundariesRight = mainBoundaries.right - thisBoundaries.right;

		this.setAttribute( 'data-anchor-position', '' );
		const detailBoundaries = details.getBoundingClientRect();
		if ( !detailBoundaries ) {
			return;
		}

		const extraWidth = ( detailBoundaries.width - thisBoundaries.width ) + 64;
		const extraHeight = ( detailBoundaries.height - thisBoundaries.height ) + 64;

		const hasSpaceOnTheRight = thisBoundariesRight > extraWidth;
		const hasSpaceAtTheBottom = thisBoundariesBottom > extraHeight;

		const mostAvailableHorizontalSpace = Math.max( thisBoundariesLeft, thisBoundariesRight );
		const mostAvailableVerticalSpace = Math.max( thisBoundariesBottom, thisBoundariesTop );

		const horizontalMarginWhenCentered = ( mainBoundaries.width - ( detailBoundaries.width - thisBoundaries.width ) ) / 2;
		const horizontalOffset = ( thisBoundariesLeft + ( thisBoundaries.width / 2 ) ) - horizontalMarginWhenCentered;

		let xAxis = 'left';
		let yAxis = 'top';

		if ( mostAvailableHorizontalSpace < extraWidth ) {
			xAxis = 'center';
		} else if ( hasSpaceOnTheRight ) {
			xAxis = 'left';
		} else {
			xAxis = 'right';
		}

		if ( mostAvailableVerticalSpace < extraHeight ) {
			yAxis = 'top';
		} else if ( hasSpaceAtTheBottom ) {
			yAxis = 'top';
		} else {
			yAxis = 'bottom';
		}

		if ( xAxis === 'center' ) {
			details.style.left = `calc(-1 * ${horizontalOffset}px)`;
		}

		this.setAttribute( 'data-anchor-position', `${xAxis} ${yAxis}` );
	}

	closeAllOtherHotspots() {
		document.querySelectorAll<MrHotspot>( 'mr-hotspot' ).forEach( ( otherHotspot ) => {
			if ( this === otherHotspot ) {
				return;
			}

			if ( typeof otherHotspot.close === 'function' ) {
				otherHotspot.close();
			}
		} );
	}
}

customElements.define( 'mr-hotspot', MrHotspot );
